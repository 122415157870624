.gospel-text {
  font-size: 18px;
  text-align: left;
  padding: 0px 20px;
}

.v {
  vertical-align: super;
  font-size: 0.75em; /* Adjust size relative to parent */
}


#about p {
  width: 80%;
  background: rgba(255,255,255,0.5);
  text-shadow: 0px 0px 5px #ffffff, 0px 0px 9px #ffffff, 0px 0px 9px #ffffff, 0px 0px 9px #ffffff, 0px 0px 9px #ffffff !important;
  text-align: center; 
  margin: 20px 0px !important;
}

.h2-greeting {
  padding: 10px 20px;
}

button.brownbuttonlink {
  padding: 0px !important;
}

.loggedout-logo-title-motto {
  display: flex;
}

/* button {
  padding: 10px 20px;
  border-radius: 20px;
} */

nav ul li {
  text-align: center;
  justify-content: center;
}

.upload-img {
  margin: 0 auto !important;
  padding: 10px;
  font-size: 18px;
  margin-bottom: -20px;
  font-family: Hebrew;
  color: rgb(90, 33, 0);
  font-weight: bold;
  width: 240px;
  max-width: 90%;
  background: rgb(255, 255, 184);
  border: 1px solid gold;
}

.submit-pic {
  border: none;
  background:rgb(44, 12, 3);
  color: white;
  margin-bottom: 20px;
  font-family: Hebrew;
  font-weight: bold;
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 20px;
}

.respond-btn {
  background:rgb(90, 33, 0);
  color: white;
  margin-bottom: 20px;
  font-family: Hebrew;
  font-weight: 400;
  font-size: 18px;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
}


#books {
  scroll-margin-top: -100px;
}

.dashboard-header {
  color: rgb(194, 71, 0);
  width: 100%;
  text-align: center;
  background: rgb(255, 217, 196);
  padding: 25px 15px;
  margin: 0px;
  box-sizing: border-box;
  text-shadow: 0px 0px 15px white, 0px 0px 15px white, 0px 0px 15px white, 0px 0px 15px white, 0px 0px 15px white;
}

.dashboard-data {
  background: rgb(255, 255, 172);
  padding: 15px;
}

.dashboard-data-2 {
  background: rgb(187, 211, 228);
  padding: 15px;
}

.dashboard-data-3 {
  background: rgb(213, 255, 226);
  padding: 15px;
}

.data-header {
  color: rgb(0, 46, 107);
  margin: 20px 0px;
  text-shadow: 0px 0px 15px white, 0px 0px 15px white, 0px 0px 15px white, 0px 0px 15px white, 0px 0px 15px white;
}

.action-button {
  width: 70%;
  margin: 30px auto;
  border-radius: 25px;
  background: yellow;
  padding: 20px;
  font-size: 3rem;
  font-family: Hebrew;
  font-weight: bold;
  border: 5px solid rgb(238, 194, 0);
  text-decoration: none;
}

a {
  text-decoration: none;
}

.action-button:hover {
  background: gold;
}


.App {
  text-align: center;
}

@font-face {
  font-family: 'Hebrew';
  src: url('VarelaRound-Regular.ttf');
}



html {
  font-size: 62.5%;
}

body {
  margin: 0px;
  background: #fffcd5;
  font-family: 'Lora', serif;
}

header {
  display: flex;
  background-color: #0E0041;
  height: 290px;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
}

.loggedInHeader {
  display: flex;
  background-color: #0E0041;
  height: auto;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
  padding-left: 0px;
  flex-wrap: wrap;
}

#logo-name-motto {
  text-align: left;
  padding: 25px;
}

#logo-name {
  display: flex;
  padding-bottom: 10px;
  align-items: center;
}

#chaqarlogo {
  width: 80px;
  height: 80px;
}

#chaqarlogo2 {
  width: 140px;
  height: 140px;
}

#title-subtitle {
  text-align: center;
}

  h1 {
      color: #EAF4D3;
      font-family: Hebrew;
      font-size: 7rem;
      margin-bottom: 5px;
      margin-top: 0px;
      padding: 0px 15px;
  }

  h2 {
    color: #0E0041;
    font-family: Hebrew;
    font-size: 4rem;
    margin-bottom: 5px;
    margin-top: 8px;
}

  button {
    margin-top: 15px;
  }

  h3 {
      color: #CA907E;
      font-family: Hebrew;
      font-size: 4rem;
      margin-top: 0px;
  }

  #motto {
    margin-bottom: 13px;
  }

  .my-commentary-title {
      color: #CA907E;
      font-family: Hebrew;
      font-size: 4rem;
      margin-top: 0px;
      margin-bottom: 15px;
  }

  .commentary-text {
    font-family: Hebrew;
    font-size: 1.8rem;
    color: #0E0041;
    margin-bottom: 5px;
  }

  .edittext {
    width: 90%;
    margin: 0 auto;
  }

  h4 {
    color: #af5f46;
    font-family: Hebrew;
    font-size: 2.9rem;
    margin-top: 0px;
    margin-bottom: 0px;
    width: 100%;
    display: block;
  }

  h5 {
    color: #9b4b33;
    font-family: Hebrew;
    font-size: 2.2rem;
    margin-top: 0px;
    margin-bottom: 0px;
}

  h6 {
    font-family: Hebrew;
    font-size: 2rem;
    margin: 15px auto;
    color:rgb(44, 12, 3);
    padding: 8px;
    background-color: rgb(252, 252, 168);
    width: 70%;
    border-radius: 25px;
  }

#welcome-pic-message {
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-bottom: 10px;

}

#welcome {
  color: #f6fac4;
  padding: 0px 10px;
}

  a {
    color: #af5f46;
    font-family: Hebrew;
    font-size: 2.9rem;
    margin-top: 0px;
    margin-bottom: 0px;
  }

nav {
  background-color: #994636;
  margin: 0px;
  padding: 0px;
}

nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 0px;
  margin-left: 0px;
  padding-left: 0px;
}

nav ul li {
  font-size: 3rem;
  color: #EAF4D3;
  padding: 20px;
  font-family: Hebrew;
  margin-left: 0px;
}

/* nav ul li:first-child {
  padding-left: 0px;
} */

nav ul li a {
  color: #EAF4D3;
  text-decoration: none; 
  font-size: 2.4rem !important;
}

nav ul li a:hover {
  color: #0E0041;
  font-weight: bold; 
}

#loggedInMenu {
  background-color: #ffe48b;
  margin: 0px;
  padding: 10px;
}

#loggedInMenu ul {
  list-style: none;
  display: flex;
  justify-content: center;
  margin: 0px;
  margin-left: 0px;
  padding-left: 0px;
}

#loggedInMenu ul li {
  font-size: 3rem;
  color: #994636;
  padding: 20px;
  font-family: Hebrew;
  margin-left: 0px;
  text-shadow: 0px 0px 15px rgb(255, 251, 213), 0px 0px 15px rgb(255, 255, 255), 0px 0px 15px rgb(255, 255, 255), 0px 0px 15px white;
}

/* #loggedInMenu ul li:first-child {
  padding-left: 0px;
} */

#loggedInMenu ul li a {
  color: #994636;
  text-decoration: none; 
}

#music {
}

#loggedInMenu ul li a:hover {
  color: #0E0041;
  font-weight: bold; 
}

#about {
  background-image: url(desertfull.jpg);
  background-size: cover;
  height: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#about p {
  margin: 0px;
  padding: 20px;
  text-align: center;
  width: 80%;
  font-size: 2rem;
  color: #0E0041;
  text-shadow: 0px 0px 5px #ffffff, 0px 0px 5px #ffffff, 0px 0px 5px #ffffff, 0px 0px 5px #ffffff;
}

#about-greg {
  padding-top: 40px;
}

.publicitypics {
  width: 350px;
  border-radius: 20px;
  border: 8px solid #ecad8f
}


.gregpic {
  width: 30%;
  float: left;
  margin: 20px;
  margin-top: 0px;
  margin-right: 35px;
  border-radius: 10px;
  border: 2px solid white;
}

#genericProfilePic {
  width: 200px;
}

.about-greg-box {
  width: 80%;
  text-align: left;
  margin: 0 auto;
  font-size: 2rem;
  color: #0C012E;
  
}

.daily-buttons-1 {
  padding: 30px 20px;
  margin: 10px;
  width: 300px;
  background: #00350F;
  border-radius: 10px;
  border: 5px solid rgb(114, 160, 138);
  cursor: pointer;
}

.daily-buttons-2 {
  padding: 30px 20px;
  margin: 10px;
  width: 300px;
  background: #240035;
  border-radius: 10px;
  border: 5px solid rgb(141, 114, 160);
  cursor: pointer;
}

.daily-buttons-3 {
  padding: 30px 20px;
  margin: 10px;
  width: 300px;
  background: #00043b;
  border-radius: 10px;
  border: 5px solid rgb(114, 121, 160);
  cursor: pointer;
}

.dailydate1 {
  border-radius: 10px;
  background: rgb(114, 160, 138);
  padding: 5px;
  font-size: 1.6rem;
  font-family: Hebrew;
  color: #00350F;
}

.dailydate2 {
  border-radius: 10px;
  background: rgb(141, 114, 160);
  padding: 5px;
  font-size: 1.6rem;
  font-family: Hebrew;
  color: #240035;
}

.dailydate3 {
  border-radius: 10px;
  background: rgb(114, 121, 160);
  padding: 5px;
  font-size: 1.6rem;
  font-family: Hebrew;
  color: #00043b;
}

.bible-books-buttons {
  width: 75%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.link {
  text-decoration: none;
}

.bible-book-button {
  width: 220px;
  min-height: 45px;
  margin: 15px;
  border: 2px solid rgb(207, 178, 98);
}

.bible-chapters-buttons {
  width: 75%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.bible-chapter-button {
  width: 70px;
  height: 70px;
  margin: 15px;
}

.autowidth {
  width: auto;
}


.bible-text {
  padding-top: 20px;
  font-size: 1.9rem;
  width: 80%;
  margin: 0 auto;
  text-align: left !important;
}

.bible-text div {
  padding: 10px 0px !important;
}

.footnote {
  background: rgb(252, 250, 176);
  padding: 4px;
  border-radius: 10px;
  font-size: 1.5rem;
  margin-right: 8px;
}

sup {
  background: rgb(252, 250, 176);
  padding: 4px;
  border-radius: 10px;
  font-size: 1.5rem;
  vertical-align: middle;
  margin-left: 8px;
}

.chapter-sequence {
  border-bottom: 3px solid rgba(68, 15, 2, 0.952);
  width: 100% !important;
  margin: 0 auto;
}

.hr {
  width: 100%;
  background-color: brown;
  height: 5px;
  border: none;
  display: block;
}

.bible-text-hebrew {
  font-size: 3rem;
  padding-top: 20px;
  width: 80%;
  margin: 0 auto;
  text-align: right !important;
}

.bible-text-hebrew div {
  padding: 10px 0px;
}


input {
  border-radius: 7px;
  height: 25px;
  margin: 5px;
}

#login {
  height: auto;
  width: auto;
  font-size: 18px;
  padding: 7px;
}

#bigbutton {
  height: auto;
  width: auto;
  font-size: 18px;
  padding: 7px;
  margin-bottom: 0px;
  border-radius: 30px;
}

.roundedbox {
  background: #e5eff7;
  border-radius: 10px;
  padding: 8px;
  width: 70%;
  margin: 8px auto;
}

.roundedbox2 {
  background: #fffed8;
  border-radius: 10px;
  padding: 8px;
  width: 70%;
  margin: 8px auto;
}

.roundedbox3 {
  background: #bcf1c5;
  border-radius: 10px;
  padding: 8px;
  width: 70%;
  margin: 8px auto;
}

.roundedbox4 {
  background: rgba(255, 255, 255, 0.59);
  border-radius: 10px;
  padding: 8px;
  width: 70%;
  margin: 8px auto;
}


#noaccount {
  font-family: Hebrew;
  font-size: 2rem;
}

textarea {
  width: 400px;
  height: 200px;
  border-radius: 10px;
  font-family: Hebrew;
  font-size: 1.6rem;
  margin-bottom: 30px;
  display: block;
  margin: 0 auto;
}


#profilepic {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  object-position: center top;
  object-fit: cover;
  max-width: 100%;
  margin: 40px;
  margin-top: 0px !important;
  box-sizing: border-box;
}

/* Videos-------------- */

.video {
  background-color: #fcf3cd;
  border-radius: 15px;
  padding: 15px;
  width: 50%;
  margin: 0 auto;
}

/* The Forum Page-------------------------- */

#discussionroombuttontitle {
  color: #EAF4D3;
  font-size: 30px;
  margin: 0;
  display: block;
  padding: 20px;
  text-decoration: none;
}

#torahapplications-button {
    width: 600px;
    height: 130px;
    background: #0C012E;
    color: #EAF4D3;
    margin: 15px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
}

#torahapplications-bg {
  background: #0C012E;
  min-height: 100vh;
}

#torahappications-title {
  color: #98abff;
}

#torahapplications-subtitle {
  color: #4f7eff;

}

#celebratingfeasts-button {
  width: 600px;
  height: 130px;
  background: #0f3517;
  color: #EAF4D3;
  margin: 15px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

#feasts-bg {
  background: #0f3517;
  min-height: 100vh;
}

#feasts-title {
  color: #dbffbd;
}

#feasts-subtitle {
  color: #8dcc74;
}

#historicalbiblebooks-button {
  width: 600px;
  height: 130px;
  background: #3e0555;
  color: #EAF4D3;
  margin: 15px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

#historicalbooks-bg {
  background: #3e0555;
  min-height: 100vh;
}

#historicalbooks-title {
  color: #e7b0fd;
}

#historicalbooks-subtitle {
  color: #b870e7;
}

#ketuvim-thewritings-button {
  width: 600px;
  height: 130px;
  background: #70165d;
  color: #EAF4D3;
  margin: 15px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

#ketuvim-bg {
  background: #70165d;
  min-height: 100vh;
}

#ketuvim-title {
  color: #ffaded;
}

#ketuvim-subtitle {
  color: #f174d6;
}

#messianicprophecy-button {
  width: 600px;
  height: 130px;
  background: #694604;
  color: #EAF4D3;
  margin: 15px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

#messianicprophecy-bg {
  background: #694604;
  min-height: 100vh;
}

#messianicprophecy-title {
  color: #ffe1aa;
}

#messianicprophecy-subtitle {
  color: #ffcd6f;
}

#followingyeshua-button {
  width: 600px;
  height: 130px;
  background: #701b01;
  color: #EAF4D3;
  margin: 15px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

#followingyeshua-bg {
  background: #701b01;
  min-height: 100vh;
}

#followingyeshua-title {
  color: #ffb39b;
}

#followingyeshua-subtitle {
  color: #e97e5d;
}

#whatisthegospel-button {
  width: 600px;
  height: 130px;
  background: #1d3d57;
  color: #EAF4D3;
  margin: 15px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

#gospel-bg {
  background: #1d3d57;
  min-height: 100vh;
}

#gospel-title {
  color: #d5ecff;
}

#gospel-subtitle {
  color: #90cbfc;
}

#allthingspaul-button {
  width: 600px;
  height: 130px;
  background: #998632;
  color: #EAF4D3;
  margin: 15px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

#allthingspaul-bg {
  background: #998632;
  min-height: 100vh;
}

#allthingspaul-title {
  color: #fff5c6;
}

#allthingspaul-subtitle {
  color: #e4cf73;
}

#israel-button {
  width: 600px;
  height: 130px;
  background: #2b539c;
  color: #EAF4D3;
  margin: 15px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

#israel-bg {
  background: #2b539c;
  min-height: 100vh;
}

#israel-title {
  color: #d1e1ff;
}

#israel-subtitle {
  color: #92b7fa;
}

#endtimesprophecy-button {
  width: 600px;
  height: 130px;
  background: #6b0c43;
  color: #EAF4D3;
  margin: 15px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

#endtimesprophecy-bg {
  background: #6b0c43;
  min-height: 100vh;
}

#endtimesprophecy-title {
  color: #ffbfe4;
}

#endtimesprophecy-subtitle {
  color: #ff81ca;
}

#thelifetocome-button {
  width: 600px;
  height: 130px;
  background: #718f55;
  color: #EAF4D3;
  margin: 15px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

#thelifetocome-bg {
  background: #718f55;
  min-height: 100vh;
}

#thelifetocome-title {
  color: #e0ffc3;
}

#thelifetocome-subtitle {
  color: #b8e78c;
}

#prayerroom-button {
  width: 600px;
  height: 130px;
  background: #000000;
  color: #EAF4D3;
  margin: 15px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

#prayerroom-bg {
  background: #000000;
  min-height: 100vh;
}

#prayerroom-title {
  color: #bdbdbd;
}

#prayerroom-subtitle {
  color: #8d8d8d;
}


#discussion-room-container {
  width: 700px;
  background: rgba(255,255,255,0.4);
  margin: 10px auto;
  padding: 20px 0px;
  border-radius: 20px;
}

#startconversation {
  font-family: 'Roboto', sans-serif;
  color: rgb(49, 30, 0);
  font-size: 30px;
  padding: 10px;
  margin-bottom: 5px;
}



#discussionroom-box {
  padding: 20px 0px;
  background: rgba(255,255,255,0.4);
}

#startconversationbox {
  background: rgb(255, 255, 255, 0.4);
  padding-bottom: 15px;
}

#submit {
  width: 120px;
  height: 40px;
  border-radius: 15px;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
}

#post {
  padding: 15px;
}

.addCommentBox {
  border-radius: 20px;
  width: 90%;
  margin: 0 auto;
  background: rgb(255, 255, 255, 0.4);  

}

.addComment {
  border-radius: 10px;
  width: 80%;
  margin: 0 auto;
}

#comments-title {
  font-size: 25px;
  color: #0e0136;
  margin-bottom: 20px;
}

#postandcomments {
  background: rgb(255, 255, 255, 0.7);
  margin: 10px;
  padding: 10px;
}

#pic-mycommentary {
  width: auto;
  margin: 0 auto;
}

#mycommentarypic {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  object-position: center top;
  object-fit: cover;
}

#smallprofilepic {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: center top;
}

.profiletext {
  font-family: Hebrew;
  color:#0C012E;
  font-size: 25px;
}

/* Dashboard */

.mediumtext {
  font-family: Hebrew;
  color:#0C012E;
  font-size: 22px;
  width: 50%;
  margin: 0 auto;
  padding: 30px;
}

.notification {
  font-family: Hebrew;
  color:#0C012E;
  font-size: 22px;
  background: #fffab9;
  width: 50%;
  margin: 0 auto;
  padding: 15px;
  border-radius: 12px;
}

.yellownotification {
  font-family: Hebrew;
  color:#0C012E;
  font-size: 22px;
  font-weight: bold;
  background: #f7e932;
  width: 50%;
  margin: 0 auto;
  padding: 15px;
  border-radius: 12px;
}

.brownroundbutton {
  background-color: #be6957;
  border-radius: 30px;
  color: #0C012E;
  width: 250px;
  margin: 0 auto;
  padding: 10px;
  font-family: Hebrew;
  font-size: 28px;
}

.brownroundbutton2 {
  background-color: #be6957;
  border-radius: 30px;
  border: 5px solid #0E0041;
  color: #0C012E;
  width: 250px;
  margin: 0 auto;
  padding: 10px;
  font-family: Hebrew;
  font-size: 28px;
}

.brownroundbutton3 {
  background-color: #be6957;
  border-radius: 30px;
  border: 5px solid #0E0041;
  color: #0C012E;
  width: 200px;
  margin: 0 auto;
  padding: 10px;
  font-family: Hebrew;
  font-size: 22px;
}

.brownroundbutton2:hover {
  background: #f19784;
}

.brownbar {
  width: 100%;
  padding: 10px 0px;
  box-sizing: border-box;
  background-color: #be6957;
}

.brownbuttonlink {
  font-family: Hebrew;
  font-size: 28px;
  color: #0C012E;
  text-decoration: none;
  background: none;
  border: none;
  padding-top: none;
  margin-top: 0px;
  cursor: pointer;
}

#iframeopen {
  height: 600px; 
  width: 99.5%;
}

#iframeclosed {
  height: 0px;
}

/* The Community */

.user {
  width: 500px;
  border-radius: 13px;
  background: #EAF4D3;
  display: flex;
  margin: 12px auto;
  text-align: left;

}

.user-pic {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  object-position: center top;
  object-fit: cover;
  padding: 15px;
}

.user-name {
    font-family: Hebrew;
    color:#0C012E;
    font-size: 24px;
    font-weight: bold;
    padding: 0px;
    margin-bottom: 0px;
}

.smallbrownbutton {
  background: #be6957;
  border-radius: 30px;
  width: 150px;
  display: inline-block;
  padding: 8px;
  margin: 8px;
  text-align: center;
}

.smallbrownbuttonlink {
  font-family: Hebrew;
  font-size: 20px;
  color: #0C012E;
  text-decoration: none;
}

h3#wallname {
  color: #0C012E;
  text-shadow: 0 0 10px rgb(255, 255, 255), 0 0 10px rgb(255, 255, 255), 0 0 10px rgb(255, 255, 255);
  
}

#wall {
  background-image: url('wall.jpg');
  background-size: cover;
  min-height: 100vh;
}

@media (max-width: 482px) {

  .upload-img {
    margin: 0 auto;
  }

  #logo-name h1 {
    font-size: 3.5rem !important;
    padding-right: 0px !important;
  }

  #logo-name #chaqarlogo {
    width: 50px;
    height: 50px;
  }

  #chaqarlogo2 {
    width: 50px !important;
    height: 50px !important;
  }
  
}

@media (max-width: 700px) {

  #logo-name-motto {
    text-align: left;
    padding: 15px;
  }

  #loggedInMenu ul li a {
    font-size: 2.2rem;
  }

      h1 {
        color: #EAF4D3;
        font-family: Hebrew;
        font-size: 5rem;
        margin-bottom: 5px;
    }

      h2 {
        color: #0E0041;
        font-family: Hebrew;
        font-size: 2.8rem;
        margin-bottom: 5px;
        margin-top: 8px;
      }



      h3 {
          color: #CA907E;
          font-family: Hebrew;
          font-size: 2.5rem;
          margin-top: 0px;
      }

      header {
        display: flex;
        background-color: #0E0041;
        height: 270px;
        justify-content: center;
        margin-bottom: 0px;
      }

      

      #about p {
        width: 80%;
        background: rgba(255,255,255,0.5);
        text-shadow: 0px 0px 5px #ffffff;
        text-align: center; 
        margin: 10px 0px;
      }

      .publicitypics {
        width: 80%;
      }

      nav {
        padding: 0px 15px;
      }

      nav ul {
        padding: 0px 20px;
        box-sizing: border-box;
      }

      nav ul li {
        font-size: 2.2rem;
      }

      #loggedInMenu ul {
        flex-direction: column;
      }

      #loggedInMenu ul li {
        font-size: 2.2rem;
        padding: 10px;
      }

      #about {
        height: auto;
        padding-top: 30px;
        padding-bottom: 30px;
      }

      .gregpic {
        width: 100%;
        float: none;
        margin: 0px;
        margin-top: 25px;
        border-radius: 10px;
        border: 2px solid white;
      }

      .bible-chapters-buttons {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }

      .bible-books-buttons {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }

      textarea {
        width: 80%;
        height: 200px;
      }

      .roundedbox {
        width: 90%;
      }

      .roundedbox2 {
        width: 90%;
      }

      .roundedbox3 {
        width: 90%;
      }


      /* Dashboard */

      .notification {
        width: 80%;
      }

      .yellownotification {
        width: 80%;
      }

      .mediumtext {
        width: 80%;
      }

      /* Videos-------------- */

      .video {
        background-color: #fcf3cd;
        border-radius: 15px;
        padding: 8px;
        width: 80%;
      }

      /* The Forum------- */

      #torahapplications-button {
        width: 90%;
        height: 130px;
        background: #0C012E;
        color: #EAF4D3;
        margin: 15px auto;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
    }
    
    #celebratingfeasts-button {
      width: 90%;
      height: 130px;
      background: #0f3517;
      color: #EAF4D3;
      margin: 15px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
    }
    
    #historicalbiblebooks-button {
      width: 90%;
      height: 130px;
      background: #3e0555;
      color: #EAF4D3;
      margin: 15px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
    }
    
    #ketuvim-thewritings-button {
      width: 90%;
      height: 130px;
      background: #70165d;
      color: #EAF4D3;
      margin: 15px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
    }
    
    #messianicprophecy-button {
      width: 90%;
      height: 130px;
      background: #694604;
      color: #EAF4D3;
      margin: 15px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
    }
    
    #followingyeshua-button {
      width: 90%;
      height: 130px;
      background: #701b01;
      color: #EAF4D3;
      margin: 15px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
    }
    
    #whatisthegospel-button {
      width: 90%;
      height: 130px;
      background: #1d3d57;
      color: #EAF4D3;
      margin: 15px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
    }
    
    #allthingspaul-button {
      width: 90%;
      height: 130px;
      background: #704504;
      color: #EAF4D3;
      margin: 15px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
    }
    
    #israel-button {
      width: 90%;
      height: 130px;
      background: #2b539c;
      color: #EAF4D3;
      margin: 15px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
    }
    
    #endtimesprophecy-button {
      width: 90%;
      height: 130px;
      background: #6b0c43;
      color: #EAF4D3;
      margin: 15px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
    }
    
    #thelifetocome-button {
      width: 90%;
      height: 130px;
      background: #718f55;
      color: #EAF4D3;
      margin: 15px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
    }
    
    #prayerroom-button {
      width: 90%;
      height: 130px;
      background: #000000;
      color: #EAF4D3;
      margin: 15px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
    }

    h4 {
      font-size: 23px;
      text-align: center;
      width: 100%;
      margin: 0 auto;
    }

    #discussion-room-container {
      width: 100%;
      background: rgba(255,255,255,0.4);
      margin: 10px auto;
      padding: 20px 0px;
      border-radius: 0px;
    }

    #startconversation {
      font-family: 'Roboto', sans-serif;
      color: rgb(49, 30, 0);
      font-size: 25px;
      padding: 10px;
      
    }

    /* The Community */

    .user {
      width: 90%;
    }

    .smallbrownbutton {
      width: 100px;
    }
}

@media (max-width: 827px) {

  .loggedout-logo-title-motto {
    flex-direction: column;
    align-items: center;
  }

  #chaqarlogo2 {
    width: 90px;
    height: 90px;
    margin-bottom: 15px;
  }

}

